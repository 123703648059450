import "./Atoms.scss";
import { FunctionComponent } from "react";

type ParagraphProps = {
  align: "left" | "center" | "right";
};

export const Paragraph: FunctionComponent<ParagraphProps> = ({
  align,
  children,
}) => <p className={`align-${align}`}>{children}</p>;
