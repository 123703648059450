import { useEffect } from "react";

export const useEnterToSubmit = (cb: () => any) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (
        event.code === "Enter" ||
        event.code === "NumpadEnter" ||
        event.keyCode === 13
      ) {
        cb();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  });
};
