import { AppContext } from "../../handlers/AppContext";
import {
  CcButton,
  CcTextInput,
} from "@madmobile/concierge-connect-react-components";
import {
  Column,
  Error,
  Paragraph,
  Spinner,
  Title,
} from "../../components/Atoms";
import { EventDetail, TextInput } from "../../types/TextInput";
import { isEmpty, isNotEmail } from "../../helpers/validators";
import { useConciergeConnectLogin } from "../../handlers/api";
import { useContext, useEffect, useState } from "react";
import { useEnterToSubmit } from "../../hooks/useEnterToSubmit";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function Home() {
  useEnterToSubmit(handleSubmitForm);
  const { width } = useWindowDimensions();
  const history = useHistory();
  const { setCustomer, storeInfo } = useContext(AppContext);
  const { t } = useTranslation();

  const [error, setError] = useState<{ critical: boolean; text: string }>({
    critical: false,
    text: "",
  });

  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [userEmail, setUserEmail] = useState<TextInput>({
    validationErrors: [],
    value: "",
  });

  const [password, setPassword] = useState<TextInput>({
    validationErrors: [],
    value: "",
  });

  const Validate: { [key: string]: any } = {
    email: {
      set: setUserEmail,
      validators: [
        {
          check: isEmpty,
          error: t("common.fieldRequired")
        },
        {
          check: isNotEmail,
          error: t("common.invalidEmail")
        }
      ],
    },
    password: {
      set: setPassword,
      validators: [{
        check: isEmpty,
        error: t("common.fieldRequired")
      }],
    },
  };

  const handleInput = ({ detail: { name, value } }: EventDetail) => {
    const validationErrors: Array<string> = [];
    const input = Validate[name];
    input.validators.forEach((validator: any) => {
      if (validator.check(value)) {
        validationErrors.push(validator.error);
      }
    });
    input.set({ validationErrors, value });
    return validationErrors;
  };

  const hasValidationErrors = () => {
    handleInput({ detail: { name: "email", value: userEmail.value } });
    handleInput({ detail: { name: "password", value: password.value } });
    return (
      userEmail.value === "" ||
      userEmail.validationErrors.length >= 1 ||
      password.value === "" ||
      password.validationErrors.length >= 1
    );
  };

  useEffect(() => {
    if (!storeInfo?.deviceId) {
      setError({ critical: true, text: t("view.checkIn.error.noDeviceId") });
    }
  }, [storeInfo?.deviceId, t]);

  const loginConnectCustomer = useConciergeConnectLogin();

  async function handleSubmitForm() {
    if (!hasValidationErrors() && !isFetching && storeInfo.deviceId) {
      setIsFetching(true);
      try {
        const customer = await loginConnectCustomer(storeInfo.deviceId, {
          password: password.value,
          username: userEmail.value,
        });
        setCustomer(customer);
        history.push("/login/welcome");
      } catch {
        setError({
          critical: false,
          text: t("view.login.couldntFindInformation"),
        });
        setIsFetching(false);
      }
    }
  }

  return (
    <Column>
      <Title size={"h1"}>{t("view.login.accountLogin")}</Title>
      <Paragraph align={"center"}>{t("view.login.accessInfo")}</Paragraph>
      {error.text !== "" && <Error text={error.text} />}
      <CcTextInput
        autoFocus={true}
        isPasswordInput={false}
        label={`*${t("common.email")}`}
        name={"email"}
        onChanged={handleInput}
        style={{
          marginBottom: width <= 375 ? "15px" : "20px",
          pointerEvents: error.critical && "none",
        }}
        validationErrors={userEmail.validationErrors}
      />
      <CcTextInput
        isPasswordInput={true}
        label={`*${t("common.password")}`}
        name={"password"}
        onChanged={handleInput}
        style={{
          marginBottom: width <= 375 ? "20px" : "30px",
          pointerEvents: error.critical && "none",
        }}
        validationErrors={password.validationErrors}
      />
      <CcButton
        btnSize={"sm"}
        onClick={() => handleSubmitForm()}
        primary={true}
        style={{
          opacity: error.critical && 0.5,
          pointerEvents: error.critical && "none",
        }}
      >
        {isFetching ? <Spinner /> : t("common.signIn")}
      </CcButton>
    </Column>
  );
}
