import { Column, Paragraph, Title } from "../components/Atoms";
import { useTranslation } from "../hooks/useTranslation";

export default function NotFound() {
  const { t } = useTranslation();

  return (
    <Column>
      <Title size={"h1"}>Not found</Title>
      <Paragraph align={"center"}>{t("view.checkIn.error.notFound")}</Paragraph>
    </Column>
  );
}
