import { AppContext } from "./AppContext";
import { CustomerInfo } from "../types/AppContext";
import { useCallback, useContext } from "react";
import axios, { AxiosResponse } from "axios";
import qs from "qs";

function useCustomerCommerceLogin() {
  const { authUrl } = useContext(AppContext);
  return useCallback(
    async (credentials: { password: string; username: string }) => {
      return axios
        .post<string, AxiosResponse<{ access_token: string }>>(
          authUrl,
          qs.stringify(credentials),
          {
            headers: { "content-type": "application/x-www-form-urlencoded" },
          }
        )
        .then((res) => {
          return res.data.access_token;
        });
    },
    [authUrl]
  );
}

export function useConciergeConnectCustomerCheckIn() {
  const { coreServicesAPI } = useContext(AppContext);
  const loginCommerceCustomer = useCustomerCommerceLogin();

  return useCallback(
    (deviceId: string, credentials: { password: string; username: string }) => {
      return loginCommerceCustomer(credentials).then((accessToken) => {
        return coreServicesAPI
          .callEndpoint({
            data: {
              accessToken,
              deviceId,
            },
            endpointName: "concierge-connect-microsite-check-in",
          })
          .then((res) => res.result as CustomerInfo);
      });
    },
    [coreServicesAPI, loginCommerceCustomer]
  );
}

export function useConciergeConnectGuestCheckIn() {
  const { coreServicesAPI } = useContext(AppContext);

  return useCallback(
    (deviceId: string, customerName: string) => {
      return coreServicesAPI
        .callEndpoint({
          data: {
            customerName,
            deviceId,
          },
          endpointName: "concierge-connect-microsite-check-in",
        })
        .then((res) => res.result as CustomerInfo);
    },
    [coreServicesAPI]
  );
}

export function useConciergeConnectLogin() {
  const { coreServicesAPI } = useContext(AppContext);
  const loginCommerceCustomer = useCustomerCommerceLogin();

  return useCallback(
    (deviceId: string, credentials: { password: string; username: string }) => {
      return loginCommerceCustomer(credentials).then((accessToken) => {
        return coreServicesAPI
          .callEndpoint({
            data: {
              accessToken,
              deviceId,
            },
            endpointName: "concierge-connect-microsite-login",
          })
          .then((res) => res.result as CustomerInfo);
      });
    },
    [coreServicesAPI, loginCommerceCustomer]
  );
}
