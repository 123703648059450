import "./style/index.scss";
import "@madmobile/concierge-connect-style-dictionary/dist/variables.css";
import { ConciergeConnectMicrositeConfig } from "./types/config";
import {
  ConciergeConnectStyleDictionary,
  setDefaultFontFaceDefinitions,
  setFontFaceDefinitions,
} from "@madmobile/concierge-connect-style-dictionary";
import { CoreServicesAPI } from "@madmobile/core-services-sdk";
import { BrowserRouter as Router } from "react-router-dom";
import { getConfig } from "./utils/getConfig";
import { initCoreServicesAPI } from "./coreServices";
import { initReactI18next } from "react-i18next";
import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
import configI18n from "./assets/i18n";
import i18next from "i18next";

// These assets are populated at build-time.
import "./custom/fonts.css";
import "./custom/variables.css";

i18next.use(initReactI18next);
configI18n();

function renderApp(
  config: ConciergeConnectMicrositeConfig,
  coreServicesAPI: CoreServicesAPI
) {
  ReactDOM.render(
    <Router>
      <React.StrictMode>
        <App config={config} coreServicesAPI={coreServicesAPI} />
      </React.StrictMode>
    </Router>,
    document.getElementById("root")
  );
}

//Allow Canary Tag Override
const urlParams = new URLSearchParams(window.location.search);
const queryParam = urlParams.get("canary");

initCoreServicesAPI(queryParam).then((coreServices) => {
  if (process.env.NODE_ENV === "development") {
    setDefaultFontFaceDefinitions();
    coreServices
      .callEndpoint({
        endpointName: "concierge-connect-microsite-config",
      })
      .then((res) => {
        const config = res.result as ConciergeConnectMicrositeConfig;
        renderApp(config, coreServices);

        const styleLink = document.createElement("link");
        styleLink.rel = "stylesheet";
        styleLink.href = config.assets.variables;
        document.head.appendChild(styleLink);

        fetch(config.assets.config)
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            throw new Error(res.statusText);
          })
          .then((data: ConciergeConnectStyleDictionary) => {
            if (data?.asset?.font) {
              setFontFaceDefinitions(
                config.assets.baseAssetUrl,
                data.asset.font
              );
            }
          });
      });
  } else {
    renderApp(getConfig(), coreServices);
  }
});
