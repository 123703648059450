import { Home, NotFound, Welcome } from "./";
import { RouteInterface } from "../../types/Routes";

const LoginRoutes: Array<RouteInterface> = [
  {
    component: Home,
    path: "/",
  },
  {
    component: Welcome,
    path: "/welcome",
  },
  {
    component: NotFound,
    path: "/404",
  },
];

export default LoginRoutes;
