import { Route } from "react-router-dom";
// Available routes for the app
import CheckinRoutes from "./Check-in/Routes";
import LoginRoutes from "./Login/Routes";

const availableRoutes = [
  {
    routes: LoginRoutes,
    slash: "login",
  },
  {
    routes: CheckinRoutes,
    slash: "check-in",
  },
];

export const RouteBuilder = () => {
  return (
    <>
      {availableRoutes.map(({ routes, slash }) =>
        routes.map(({ component, path }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={true}
              path={`/${slash}${path}`}
            />
          );
        })
      )}
    </>
  );
};
