import { AppContext } from "../../handlers/AppContext";
import {
  CcButton,
  CcTextInput,
} from "@madmobile/concierge-connect-react-components";
import {
  Column,
  Error,
  Paragraph,
  Spinner,
  Title,
} from "../../components/Atoms";
import { EventDetail, TextInput } from "../../types/TextInput";
import { isEmpty } from "../../helpers/validators";
import { useConciergeConnectGuestCheckIn } from "../../handlers/api";
import { useContext, useState } from "react";
import { useEnterToSubmit } from "../../hooks/useEnterToSubmit";
import { useHistory } from "react-router-dom";
import { useTranslation } from "../../hooks/useTranslation";

export default function Guest() {
  const checkInGuest = useConciergeConnectGuestCheckIn();

  const { setCustomer, storeInfo } = useContext(AppContext);
  const [error, setError] = useState<string>("");

  const history = useHistory();
  const { t } = useTranslation();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [customerName, setCustomerName] = useState<TextInput>({
    validationErrors: [],
    value: "",
  });

  const handleSubmitForm = async () => {
    if (!hasValidationErrors() && !isFetching && storeInfo.deviceId) {
      setIsFetching(true);
      try {
        await checkInGuest(storeInfo.deviceId, customerName.value);
        setCustomer({ firstName: customerName.value });
        history.push("/check-in/welcome");
      } catch {
        setError(t("view.checkIn.error.unableToReachCC"));
      } finally {
        setIsFetching(false);
      }
    }
  };

  useEnterToSubmit(handleSubmitForm);

  const handleInput = (event: EventDetail) => {
    const { detail }: { detail: { value: string } } = event;
    const validationErrors: Array<string> = [];
    if (isEmpty(detail.value))
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      validationErrors.push(t("common.fieldRequired"));
    setCustomerName({ validationErrors, value: detail.value });
    return validationErrors;
  };

  const hasValidationErrors = () => {
    const errors = handleInput({
      detail: { name: "customer", value: customerName.value },
    });
    if (errors.length >= 1) return true;
    return false;
  };

  return (
    <Column>
      <Title size={"h1"}>{t("view.guest.whoIsHere")}</Title>

      <Paragraph align={"center"}>{t("view.guest.nameQuestion")}</Paragraph>
      {error ? <Error text={error} /> : null}
      <CcTextInput
        isPasswordInput={false}
        label={`*${t("view.guest.yourName")}`}
        name={"username"}
        onChanged={handleInput}
        style={{ marginBottom: "20px" }}
        validationErrors={customerName.validationErrors}
      />
      <CcButton
        btnSize={"sm"}
        onClick={() => handleSubmitForm()}
        primary={true}
      >
        {isFetching ? <Spinner /> : t("view.guest.checkIn")}
      </CcButton>
    </Column>
  );
}
