import { Guest, Home, NotFound, Welcome } from "./";
import { RouteInterface } from "../../types/Routes";

const CheckinRoutes: Array<RouteInterface> = [
  {
    component: Home,
    path: "/",
  },
  {
    component: Welcome,
    path: "/welcome",
  },
  {
    component: Guest,
    path: "/guest",
  },
  {
    component: NotFound,
    path: "/404",
  },
];

export default CheckinRoutes;
