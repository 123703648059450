import {
  AppContextInterface,
  CustomerInfo,
  StoreInfo,
} from "../types/AppContext";
import { ConciergeConnectMicrositeConfig } from "../types/config";
import { CoreServicesAPI } from "@madmobile/core-services-sdk";
import {
  FunctionComponent,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SupportedLanguage } from "../context/TranslationContext";
import { useQuery } from "../hooks/useQuery";
import { useTranslation } from "../hooks/useTranslation";

export const AppContext = createContext<AppContextInterface>(
  undefined as unknown as AppContextInterface
);

const AppProvider: FunctionComponent<{
  config: ConciergeConnectMicrositeConfig;
  coreServicesAPI: CoreServicesAPI;
}> = ({ children, config, coreServicesAPI }) => {
  const [customer, setCustomer] = useState<CustomerInfo | null>(null);
  const [authUrl, setAuthUrl] = useState<string>("");
  const { changeLanguage } = useTranslation();
  const query = useQuery();

  const storeInfo = useMemo<StoreInfo>(
    () => ({
      deviceId: query.get("deviceId"),
      language: query.get("language"),
    }),
    [query]
  );

  useEffect(() => {
    storeInfo.deviceId &&
      coreServicesAPI
        .callEndpoint({
          data: {
            deviceId: storeInfo.deviceId,
          },
          endpointName: "concierge-connect-microsite-auth-url",
        })
        .then((response) => {
          setAuthUrl(response.result);
        })
  }, [coreServicesAPI, storeInfo]);

  const lang = storeInfo?.language as SupportedLanguage;
  useEffect(() => {
    if (lang) {
      changeLanguage(lang);
    }
  }, [lang, changeLanguage]);

  return (
    <AppContext.Provider
      value={{
        authUrl,
        config,
        coreServicesAPI,
        customer,
        setCustomer,
        storeInfo,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
