import { AppContext } from "../../handlers/AppContext";
import { Column, Paragraph, Title } from "../../components/Atoms";
import { useContext } from "react";
import { useTranslation } from "../../hooks/useTranslation";

export default function Welcome() {
  const { t } = useTranslation();
  const { customer } = useContext(AppContext);

  return (
    <Column>
      <Title size={"h1"}>
        {t("view.welcome.welcome__name", { name: customer?.firstName })}
      </Title>
      <Paragraph align={"center"}>{t("view.welcome.storeWelcome")}</Paragraph>
    </Column>
  );
}
