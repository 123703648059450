import "./Header.scss";
import { VFC } from "react";

export const Header: VFC<{ logo: string }> = ({ logo }) => {
  return (
    <div className="header">
      <img alt="" src={logo} />
    </div>
  );
};
