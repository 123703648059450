import "./Atoms.scss";
import { useTranslation } from "../../hooks/useTranslation";

export const Divider = () => {
  const { t } = useTranslation();
  return (
    <div className="divider">
      <span className="line"></span>
      <span className="text">{t("common.or")}</span>
      <span className="line"></span>
    </div>
  );
};
