import { ConciergeConnectMicrositeConfig } from "./types/config";
import { CoreServicesAPI } from "@madmobile/core-services-sdk";
import { Header } from "./components/Header/Header";
import { RouteBuilder } from "./views/RouteBuilder";
import { TranslationContextProvider } from "./context/TranslationContext";
import { getAssetUrl } from "@madmobile/concierge-connect-style-dictionary";
import AppProvider from "./handlers/AppContext";
import React, { useCallback } from "react";

const App: React.VFC<{
  config: ConciergeConnectMicrositeConfig;
  coreServicesAPI: CoreServicesAPI;
}> = ({ config, coreServicesAPI }) => {
  const fetchTranslations = useCallback(async (lang) => {
    return (await import(`./assets/i18n/${lang}/microsite.json`)).default;
  }, []);

  return (
    <TranslationContextProvider
      fetchTranslations={fetchTranslations}
      namespace="microsite"
    >
      <AppProvider config={config} coreServicesAPI={coreServicesAPI}>
        <Header
          logo={getAssetUrl(
            config.assets.baseAssetUrl,
            config.assets.images.logo
          )}
        />
        <RouteBuilder />
      </AppProvider>
    </TranslationContextProvider>
  );
};

export default App;
