import LanguageDetector from "i18next-browser-languagedetector";
import i18next from "i18next";

import de from "./de/microsite.json";
import enGB from "./en-GB/microsite.json";
import enUS from "./en-US/microsite.json";
import es from "./es/microsite.json";
import fr from "./fr/microsite.json";
import it from "./it/microsite.json";
import jp from "./jp/microsite.json";
import nl from "./nl/microsite.json";
import sv from "./sv/microsite.json";

export { default as de } from "./de/microsite.json";
export { default as enGB } from "./en-GB/microsite.json";
export { default as enUS } from "./en-US/microsite.json";
export { default as es } from "./es/microsite.json";
export { default as fr } from "./fr/microsite.json";
export { default as it } from "./it/microsite.json";
export { default as nl } from "./nl/microsite.json";
export { default as sv } from "./sv/microsite.json";
export { default as jp } from "./jp/microsite.json";

const languageDetector = new LanguageDetector();

const configI18n = () => {
  i18next.use(languageDetector).init({
    debug: process.env.NODE_ENV === "development",
    detection: {
      order: [
        "querystring",
        "navigator",
        "cookie",
        "localStorage",
        "sessionStorage",
        "htmlTag",
        "path",
        "subdomain",
      ],
    },
    fallbackLng: "en-US",
    fallbackNS: "microsite",
    lng: "en-US",
    ns: ["microsite"],
    resources: {
      de: {
        microsite: de,
      },
      "en-GB": {
        microsite: enGB,
      },
      "en-US": {
        microsite: enUS,
      },
      es: {
        microsite: es,
      },
      fr: {
        microsite: fr,
      },
      it: {
        microsite: it,
      },
      jp: {
        microsite: jp,
      },
      nl: {
        microsite: nl,
      },
      sv: {
        microsite: sv,
      },
    },
  });
};

export default configI18n;
