import {
  Namespace,
  TFunction,
  useTranslation as useI18nextTranslation,
} from "react-i18next";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const supportedLanguages = ["en-US", "fr", "de", "it", "es"] as const;
export type SupportedLanguage = typeof supportedLanguages[number];

export interface ITranslationContext<T extends Namespace> {
  changeLanguage: (lng: SupportedLanguage) => void;
  languages: Array<{
    abbreviation: string;
    id: SupportedLanguage;
    label: string;
  }>;
  selectedLanguageId: SupportedLanguage;
  t: TFunction<T>;
}

export const TranslationContext = React.createContext<ITranslationContext<any>>(
  undefined as unknown as ITranslationContext<any>
);

export function useTranslationContext<
  T extends Namespace
>(): ITranslationContext<T> {
  return useContext(TranslationContext);
}

export interface TranslationContextProviderProps<T extends Namespace> {
  fetchTranslations: (lang: SupportedLanguage) => Promise<Record<string, any>>;
  namespace: T;
}

export function TranslationContextProvider<T extends Namespace>({
  children,
  fetchTranslations,
  namespace,
}: React.PropsWithChildren<TranslationContextProviderProps<T>>) {
  const { i18n, t } = useI18nextTranslation([namespace, "core"] as Namespace);
  const [initialized, setInitialized] = useState(false);
  const [selectedLanguageId] = useState<SupportedLanguage>(
    (i18n.resolvedLanguage || supportedLanguages[0]) as SupportedLanguage
  );

  useEffect(() => {
    (async () => {
      for await (const lang of supportedLanguages) {
        try {
          const translation = await fetchTranslations(lang);
          i18n.addResourceBundle(
            lang,
            namespace as string,
            translation,
            true,
            true
          );
        } catch (err) {
          console.error(err);
        }
      }
      setInitialized(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeLanguage = useCallback<ITranslationContext<T>["changeLanguage"]>(
    (lng) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  const languages = useMemo(() => {
    return supportedLanguages.map((lang) => {
      switch (lang) {
        case "de":
          return {
            abbreviation: t("view.languageSelector.germanAbbr"),
            id: lang,
            label: t("view.languageSelector.german"),
          };
        case "en-US":
          return {
            abbreviation: t("view.languageSelector.englishAbbr"),
            id: lang,
            label: t("view.languageSelector.english"),
          };
        case "es":
          return {
            abbreviation: t("view.languageSelector.spanishAbbr"),
            id: lang,
            label: t("view.languageSelector.spanish"),
          };
        case "fr":
          return {
            abbreviation: t("view.languageSelector.frenchAbbr"),
            id: lang,
            label: t("view.languageSelector.french"),
          };
        case "it":
          return {
            abbreviation: t("view.languageSelector.italianAbbr"),
            id: lang,
            label: t("view.languageSelector.italian"),
          };
        default:
          return {
            abbreviation: t("view.languageSelector.englishAbbr"),
            id: lang,
            label: t("view.languageSelector.english"),
          };
      }
    });
  }, [t]);

  const value = useMemo(
    () => ({
      changeLanguage,
      languages,
      selectedLanguageId,
      t,
    }),
    [changeLanguage, languages, t, selectedLanguageId]
  );

  return (
    <TranslationContext.Provider value={value}>
      {initialized && children}
    </TranslationContext.Provider>
  );
}
