import "./Atoms.scss";
import { FunctionComponent } from "react";

type TitleProps = {
  size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
};

export const Title: FunctionComponent<TitleProps> = ({ children, size }) => (
  <>
    {size === "h1" && <h1>{children}</h1>}
    {size === "h2" && <h2>{children}</h2>}
    {size === "h3" && <h3>{children}</h3>}
    {size === "h4" && <h4>{children}</h4>}
    {size === "h5" && <h5>{children}</h5>}
    {size === "h6" && <h6>{children}</h6>}
  </>
);
